import axios from "axios";
import { toast } from "react-toastify";
import clearStorage, { LOCAL_STORAGE_KEYS } from "../helpers/utils";
import routes from "../routes/routes";

class API {
  constructor() {
    this.initClient();
  }

  initClient() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      headers: { "Content-Type": "application/json" },
      // withCredentials: true,
    });

    // Set auth token before making request
    this.client.interceptors.request.use(
      async (config) => {
        const updatedConfig = { ...config };
        const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
        if (token) {
          updatedConfig.headers["x-loot-auth-token"] = `${token}`;
        }
        return updatedConfig;
      },
      (error) => {
        toast.error(error?.response?.data.message);
      }
    );

    // Only return data (exclude header, status code, etc) from the response
    this.client.interceptors.response.use(
      (response) => response.data,
      (error) => {
        if (error?.response.status === 400) {
          toast.error(error?.response?.data.message);
        }
        if (error?.response.status === 401) {
          clearStorage();
          window.location.href = routes.LOGIN_PAGE;
        }
      }
    );
  }
}

const ApiIntance = new API();
export const Api = ApiIntance;
export const ApiClient = ApiIntance.client;
