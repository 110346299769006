import { useFormik } from "formik";
import React, { useState } from "react";
import { FloatingLabel, Form } from 'react-bootstrap';
import Button from "react-bootstrap/Button";
import * as Yup from 'yup';
import Logo from "../../assets/Loot-Square.svg";
import { Loading } from "../../components";
import { LOCAL_STORAGE_KEYS } from "../../helpers/utils";
import { login } from "../../redux/login/loginApi";
import routes from "../../routes/routes";


export const IconEye = ({ width, height }) => {
  return (
    <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9842 9.99993C12.9842 11.6499 11.6509 12.9833 10.0009 12.9833C8.35091 12.9833 7.01758 11.6499 7.01758 9.99993C7.01758 8.34993 8.35091 7.0166 10.0009 7.0166C11.6509 7.0166 12.9842 8.34993 12.9842 9.99993Z"
        stroke="#100B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99987 16.8918C12.9415 16.8918 15.6832 15.1584 17.5915 12.1584C18.3415 10.9834 18.3415 9.00843 17.5915 7.83343C15.6832 4.83343 12.9415 3.1001 9.99987 3.1001C7.0582 3.1001 4.31654 4.83343 2.4082 7.83343C1.6582 9.00843 1.6582 10.9834 2.4082 12.1584C4.31654 15.1584 7.0582 16.8918 9.99987 16.8918Z"
        stroke="#100B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const IconEyeClose = ({ width, height }) => {
  return (
    <svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1092 7.8916L7.89258 12.1083C7.35091 11.5666 7.01758 10.8249 7.01758 9.99993C7.01758 8.34993 8.35091 7.0166 10.0009 7.0166C10.8259 7.0166 11.5676 7.34994 12.1092 7.8916Z"
        stroke="#100B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8499 4.8084C13.3915 3.7084 11.7249 3.1084 9.99987 3.1084C7.0582 3.1084 4.31654 4.84173 2.4082 7.84173C1.6582 9.01673 1.6582 10.9917 2.4082 12.1667C3.06654 13.2001 3.8332 14.0917 4.66654 14.8084"
        stroke="#100B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.01758 16.2751C7.96758 16.6751 8.97591 16.8917 10.0009 16.8917C12.9426 16.8917 15.6842 15.1584 17.5926 12.1584C18.3426 10.9834 18.3426 9.0084 17.5926 7.8334C17.3176 7.40006 17.0176 6.99173 16.7092 6.6084"
        stroke="#100B1B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.9242 10.5833C12.7076 11.7583 11.7492 12.7166 10.5742 12.9333" stroke="#100B1B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.89102 12.1084L1.66602 18.3334" stroke="#100B1B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.3324 1.66675L12.1074 7.89175" stroke="#100B1B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};


const loginSchema = Yup.object({
  email: Yup.string().required('Email is required').email('Enter Valid Email'),
  password: Yup.string().min(8, 'Password must be of at least 8 characters').required('Password is Required'),
});



export function Login() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        const response = await login(values.email, values.password);
        localStorage.setItem(LOCAL_STORAGE_KEYS.USERID, response.data.user.id);
        localStorage.setItem(LOCAL_STORAGE_KEYS.EMAIL, response.data.user.email);
        localStorage.setItem(LOCAL_STORAGE_KEYS.FULL_NAME, response.data.user.fullName);
        localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, response.data.access_token);
        window.location.href = routes.PORTAL;
      } catch (error) {
        setIsLoading(false);
        resetForm()
        console.log('Login Failed', error);
      }
      finally {
        setIsLoading(false)
      }
    },
  });

  const { values, handleChange, handleBlur, errors, touched, isSubmitting, handleSubmit } = form;
  return (
    <>
      <Loading visible={loading} />
      <div className="app-login">
        <div className="container">
          <header className="header">
            <img src={Logo} alt="" style={{ width: '3rem', height: '3rem' }} />
            <h3 className="title">Your Partner Portal</h3>
            <p className="content">Enter your login details to access your dashboard</p>
          </header>
          <Form onSubmit={handleSubmit} className="application-form" noValidate>
            <FloatingLabel controlId="email" label="Email" className="mb-3">
              <Form.Control
                type="text"
                name="email"
                placeholder="Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!(touched.email && Boolean(errors.email))}
              />
              <p className="text-danger ms-2">  {!!(touched.email && Boolean(errors.email)) && errors.email}</p>
            </FloatingLabel>

            <FloatingLabel controlId="password" label="Password">
              <Form.Control
                name="password"
                type={showPassword ? 'text' : 'password'}
                className="input-pwd"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!(touched.password && Boolean(errors.password))}
              />
              <button onClick={handleClickShowPassword} type="button" className="btn-pwd">
                {showPassword ? <IconEye /> : <IconEyeClose />}
              </button>
            </FloatingLabel>
            <p className="text-danger ms-2">  {!!(touched.password && Boolean(errors.password)) && errors.password}</p>
            <Button disabled={isSubmitting} variant="primary" style={{ backgroundColor: '#abea84', borderColor: '#abea84', color: '#083342' }} type="submit" className="mt-4 w-100">
              Continue
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
}
