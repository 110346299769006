export const LOCAL_STORAGE_KEYS = {
  TOKEN: "portalToken",
  EMAIL: "email",
  FULL_NAME: "fullName",
  USERID: "userId",
};

export default function clearStorage() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.USERID);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.EMAIL);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.FULL_NAME);
  localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
}
