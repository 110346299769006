/* eslint-disable import/no-anonymous-default-export */
import { Dashboard } from "../views/Dashboard";
import { Login } from "../views/Login";
import NotFoundPage from "../views/NotFoundPage";
import { Portal } from "../views/Portal";
import { Portfolio } from "../views/Portfolio";
import ProtectedRoute from "./ProtedRouter";
import UnProtectedRoute from "./UnProtedRoute";
import r from "./routes";

export default [
  {
    exact: true,
    path: r.LOGIN_PAGE,
    element: (
      <UnProtectedRoute>
        <Login />
      </UnProtectedRoute>
    ),
  },
  {
    exact: true,
    path: r.HOME_PAGE,
    element: (
      <ProtectedRoute pathRedirect={r.LOGIN_PAGE}>
        <Portal />
      </ProtectedRoute>
    ),
  },
  {
    exact: true,
    path: r.PORTAL,
    element: (
      <ProtectedRoute pathRedirect={r.LOGIN_PAGE}>
        <Portal />
      </ProtectedRoute>
    ),
  },
  {
    exact: true,
    path: r.DASH_BOARD,
    element: (
      <ProtectedRoute pathRedirect={r.LOGIN_PAGE}>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    exact: true,
    path: r.PORT_FOLIO,
    element: (
      <ProtectedRoute pathRedirect={r.LOGIN_PAGE}>
        <Portfolio />
      </ProtectedRoute>
    ),
  },
  {
    path: r.NOT_FOUND_PAGE,
    element: <NotFoundPage />,
  },
];
