import { Navigate } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "../helpers/utils";
import r from "../routes/routes";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN) ?? null;

  if (token === null) {
    return <Navigate to={r.LOGIN_PAGE} replace />;
  }

  return children;
};

export default ProtectedRoute;
