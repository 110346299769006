import React from "react";
import { Input } from "reactstrap";
import {
  LineSegment,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryCursorContainer,
  VictoryLabel,
} from "victory";

export function IndexChart() {
  const TestC = (props) => {
    return (
      <LineSegment
        {...props}
        style={{ stroke: "#F189F7", strokeWidth: "3px" }}
      ></LineSegment>
    );
  };

  const chartData = [
    { x: "Feb", y: 0, label: "$0" },
    { x: "Mar", y: 10000, label: "$10,000", percent: "(36.2%)" },
    { x: "Apr", y: 14000, label: "$14,000", percent: "(36.2%)" },
    { x: "May", y: 33000, label: "$33,000", percent: "(36.2%)" },
    { x: "Jun", y: 41000, label: "$41,000", percent: "(36.2%)" },
    { x: "Jul", y: 61000, label: "$61,000", percent: "(36.2%)" },
    { x: "Aug", y: 75000, label: "$75,000", percent: "(36.2%)" },
  ];

  return (
    <>
      <div className="card-chart">
        <div className="card-body">
          <div className="cc-top">
            <h3 className="cc-heading">Balance</h3>
            <div className="">
              <Input type="select" name="select" id="exampleSelect" bsSize="sm">
                <option>All time</option>
                <option>Last Week</option>
                <option>This Months</option>
                <option>Last Months</option>
              </Input>
            </div>
          </div>
          <div className="balance-chart">
            <VictoryChart
              width={830}
              // height={250}
              style={{ grid: { stroke: "none" } }}
              containerComponent={
                <VictoryCursorContainer
                  cursorDimension="x"
                  cursorComponent={<TestC />}
                  cursorLabel={({ datum }) => {
                    if (datum) {
                      return `${chartData[Math.round(datum.x, 2) - 1].label} ${
                        chartData[Math.round(datum.x, 2) - 1].percent
                      }`;
                    }

                    return "";
                  }}
                  cursorLabelComponent={
                    <VictoryLabel
                      style={{ fill: "black", fontWeight: "600" }}
                    />
                  }
                />
              }
            >
              <VictoryArea
                // animate={{
                //   duration: 2 * 1000,
                //   onLoad: { duration: 2 * 1000 },
                // }}
                style={{
                  data: {
                    fill: "rgba(241, 137, 247,0.2)",
                    // fillOpacity: 0.55,
                    stroke: "#6555DF",
                    strokeWidth: 2,
                  },
                }}
                interpolation="natural"
                labels={({ datum }) => datum.y}
                data={chartData}
              />
              <VictoryAxis />
            </VictoryChart>
            {/* <h1 className="text-center">Chart coming soon..</h1> */}
          </div>
        </div>
      </div>
    </>
  );
}
