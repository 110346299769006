import { forwardRef } from "react";
import { RiUserFill } from "react-icons/ri";

export const NavDropdownToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    className="nav-dropdown-toggle"
    role="button"
    onClick={onClick}
    tabIndex={0}
  >
    {children}
    <div className="nav-dropdown-toggle__profile">
      <RiUserFill />
    </div>
  </div>
));
