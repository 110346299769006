import { Route, Routes } from "react-router-dom";
import routesMap from "./routes/routesMap";

function App() {
  return (
    <Routes>
      {routesMap.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </Routes>
  );
}

export default App;
