import classNames from "classnames";
import { Spinner } from "react-bootstrap";

export function Loading({
  className = "",
  label = "Loading ...",
  visible = false,
}) {
  return (
    <div
      className={classNames("loading", className, {
        "d-flex": visible,
        "d-none": !visible,
      })}
    >
      <Spinner animation="border" variant="light" role="status">
        <span className="visually-hidden">{label || "Loading ..."}</span>
      </Spinner>
      {label ? <h5 className="loading__title">{label}</h5> : null}
    </div>
  );
}
