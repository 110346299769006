import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  NavItem,
  Navbar,
} from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LogoLight } from "../../assets/Loot-Logo-White.svg";
import { ReactComponent as Logo } from "../../assets/Loot-Logo.svg";
import DatePickerIcon from "../../assets/date-picker-icon.svg";
import ImgIcFile from "../../assets/ic-file.svg";
import ImgIcMoney from "../../assets/ic-money.svg";
import ImgIcSuitcase from "../../assets/ic-suitcase.svg";
import ImgIcWallet from "../../assets/ic-wallet.svg";
import Iconmessage from "../../assets/message-time.svg";
import Iconphone from "../../assets/phone-calling.svg";
import springHR from "../../assets/spring-hr.png";
import springVR from "../../assets/spring-vr.png";
import vectorPlus from "../../assets/vector-plus.svg";
import VeronicaImg from "../../assets/veronica.png";
import { Loading, NavDropdownToggle } from "../../components";
import clearStorage, { LOCAL_STORAGE_KEYS } from "../../helpers/utils";
import { getUserInfo, getUserSnapshotData } from "../../redux/login/loginApi";
import r from "../../routes/routes";
import { Snapshot } from "./snapshot";

export function Portal() {
  const [snapshotData, setSnapshotData] = useState({
    totalApplications: 0,
    totalDeals: 0,
    totalFundedAmount: 0,
    totalCommissionAmount: 0,
  });
  const [userData, setUserData] = useState({
    totalCommissionAmount: 0,
    totalDeals: 0,
    totalFundedAmount: 0,
  });
  const [isShowLoading, setIsShowLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [snapshot, setSnapshot] = useState("allTime");

  const navigate = useNavigate();

  const initialRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const [selectedRange, setSelectedRange] = useState([initialRange]);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const handleSelect = (ranges) => {
    setSelectedRange([ranges.selection]);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 767) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      setIsShowLoading(true);
      const response = await getUserInfo();
      setUserData(response.data.partner);
      const responseSnapShot = await getUserSnapshotData(null, null);
      setSnapshotData(responseSnapShot.data.partner);
      setIsShowLoading(false);
    };
    getUserData();
  }, []);

  const toggleDateRangePicker = () => {
    setIsDateRangeOpen(!isDateRangeOpen);
  };

  const handleFilterData = async () => {
    const fromDate = moment(selectedRange[0].startDate).format("MM/DD/YYYY");
    const toDate = moment(selectedRange[0].endDate).format("MM/DD/YYYY");
    try {
      setIsShowLoading(true);
      setIsDateRangeOpen(false);
      const response = await getUserSnapshotData(fromDate, toDate);
      setSnapshotData(response.data.partner);
      setIsShowLoading(false);
      setSnapshot("Selected");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navbar className="app-navbar" bg="light" variant="light" fixed="top">
        <Navbar.Brand href="/app/dashboard">
          <Logo />
        </Navbar.Brand>
        <Nav>
          <Dropdown as={NavItem} align="end">
            <Dropdown.Toggle as={NavDropdownToggle}>
              {/* {split(userName, '@')[0]} */}
              {localStorage.getItem(LOCAL_STORAGE_KEYS.FULL_NAME)}
            </Dropdown.Toggle>
            <Dropdown.Menu className="navbar-dropdown-menu" variant="dark">
              <Dropdown.Item
                onClick={() => {
                  clearStorage();
                  navigate(r.LOGIN_PAGE);
                }}
              >
                Log out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar>
      <Container fluid className="app-container">
        <div className="portal-container">
          <div className="portal-header">
            <div
              className="portal-header__content"
              style={{
                backgroundImage: `url(${vectorPlus}), url(${vectorPlus}), url(${vectorPlus})`,
              }}
            >
              <div className="portal-header__heading">
                <img src={springVR} alt="vector" className="spring-top" />
                <div className="d-flex flex-column flex-lg-row align-items-center ">
                  {/* <img
                    alt="header logo"
                    src={ImgPortalHeaderLogo}
                  /> */}
                  <h1 className="portal-header__slogan pt-4 pt-lg-0">
                    <span>Since starting our journey together, you've</span>{" "}
                    funded{" "}
                    {userData.totalDeals === null ? 0 : userData.totalDeals}{" "}
                    deals, <span>totaling</span> $
                    {userData.totalFundedAmount === null
                      ? 0
                      : userData.totalFundedAmount.toFixed()}
                    , <span>resulting in commission earnings of</span> $
                    {userData.totalCommissionAmount === null
                      ? 0
                      : userData.totalCommissionAmount.toFixed()}
                    .
                  </h1>
                </div>
                <img src={springHR} alt="vector" className="spring-end" />
              </div>
              <div className="portal-header__card">
                <div className="portal-header__credit-content">
                  <div className="d-flex">
                    <div className="flex-fill">
                      <p className="mb-1">
                        <strong>Need support?</strong> Say hi to...
                      </p>
                      <h5 className="mb-1 font-22">Veronica Velasquez</h5>
                      <p className="mb-2">
                        Your dedicated relationship manager
                      </p>
                    </div>
                    <div className="ms-2">
                      <img
                        src={VeronicaImg}
                        alt="Instant access to capital"
                        className="img-95 max-w-none"
                      />
                    </div>
                  </div>
                  <ul className="list-unstyled mb-0">
                    <li className="mb-2 flex">
                      <img
                        src={Iconmessage}
                        alt="email"
                        className="me-2"
                        width={18}
                        height={18}
                      />
                      {/* default veronica */}
                      <button
                        className="text-decoration-underline px-0"
                        style={{
                          border: "none",
                          background: "transparent",
                          textDecorationSkipInk: "none",
                        }}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = `mailto:veronica@getloot.com?subject=Support`;
                        }}
                      >
                        veronica@getloot.com
                      </button>
                    </li>
                    <li className="flex">
                      <img
                        src={Iconphone}
                        alt="Instant access to capital"
                        className="me-2"
                        width={18}
                        height={18}
                      />
                      <button
                        className="text-decoration-underline px-0"
                        style={{
                          border: "none",
                          background: "transparent",
                          textDecorationSkipInk: "none",
                        }}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = "tel:+16179934532";
                        }}
                      >
                        +1 (617) 993-4532
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="portal-content px-4 pt-12">
            <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between portal-content__filter">
              <div className="text-secondary fw-bolder">Snapshot</div>
              <div className="date-range-selection-container mt-3 mt-lg-0">
                <Form.Control
                  type="text"
                  value={
                    snapshot === "allTime"
                      ? "All Time"
                      : `${moment(selectedRange[0].startDate).format(
                          "MM/DD/YYYY"
                        )} - ${moment(selectedRange[0].endDate).format(
                          "MM/DD/YYYY"
                        )}`
                  }
                  readOnly
                />
                <button
                  className="btn btn-date"
                  onClick={toggleDateRangePicker}
                >
                  <img src={DatePickerIcon} alt="Calendar" />
                </button>
              </div>

              {isDateRangeOpen && (
                <>
                  <div className="date-modal-container">
                    <Modal
                      show={true}
                      onHide={() => setIsDateRangeOpen(false)}
                      size="lg"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title className="text-center">
                          Select Time Period{" "}
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="date-picker-container">
                          <DateRangePicker
                            ranges={selectedRange}
                            onChange={handleSelect}
                            months={2}
                            scroll={{ enabled: true }}
                            direction={isMobile ? "vertical" : "horizontal"}
                            moveRangeOnFirstSelection={false}
                            maxDate={new Date()}
                          />
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={handleFilterData}>
                          Ok
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </>
              )}
            </div>
            <div className="mt-4 d-flex flex-column flex-lg-row row gx-3">
              {/* <Snapshot
                image={ImgIcDownload}
                bgColor="bg-yellow"
                textColor="text-secondary"
                title="Page visitors"
                amount="76"
                isComingSoon={true}
              /> */}
              <Snapshot
                image={ImgIcFile}
                bgColor="bg-violet"
                textColor="text-secondary"
                title="Applications"
                amount={
                  snapshotData.totalApplications === null
                    ? 0
                    : snapshotData.totalApplications
                }
              />
              <Snapshot
                image={ImgIcMoney}
                bgColor="bg-green"
                textColor="text-secondary"
                title="Deals funded"
                amount={
                  snapshotData.totalDeals === null ? 0 : snapshotData.totalDeals
                }
              />
            </div>
            <div className="row d-flex flex-column flex-lg-row gx-3">
              <Snapshot
                image={ImgIcSuitcase}
                bgColor="bg-secondary"
                textColor="text-white"
                title="Funded amount"
                amount={
                  snapshotData.totalFundedAmount === null
                    ? 0
                    : snapshotData.totalFundedAmount.toFixed()
                }
                isShowCurrency={true}
              />
              <Snapshot
                image={ImgIcWallet}
                bgColor="bg-violet"
                textColor="text-secondary"
                title="Commission"
                amount={
                  snapshotData.totalCommissionAmount === null
                    ? 0
                    : snapshotData.totalCommissionAmount.toFixed()
                }
                isShowCurrency={true}
              />
            </div>
          </div>
        </div>
      </Container>
      <Loading visible={isShowLoading} />
      <footer className="footer">
        <div className="footer-logo">
          <LogoLight />
        </div>
        <div className="footer-text">
          <p>
            <span className="d-block d-md-inline">
              © 2023 Loot, All rights reserved.
            </span>
          </p>
        </div>
        <div className="footer-social">
          <p>
            <Link to="https://www.linkedin.com/company/getloot" target="_blank">
              Linkedin
            </Link>
            <span className="px-2">|</span>
            <Link to="https://www.facebook.com/getloot.us" target="_blank">
              Facebook
            </Link>
          </p>
        </div>
      </footer>
    </>
  );
}
