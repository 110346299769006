import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
} from "reactstrap";
import ImgAvatar from "../../assets/avarat.svg";
import Imgmodalheader from "../../assets/coin.svg";
import ImgBannerBG from "../../assets/dashboard-banner.png";
import Imgdollar from "../../assets/dollar-front-clay.png";
import ImgLogo from "../../assets/logo.svg";
import { IndexChart } from "./IndexChart";

export function Dashboard() {
  const [modal, setModal] = useState(false);

  const togglemodal = () => setModal(!modal);
  const [dropDownMenu, setDropDownMenu] = useState(false);
  const toggle = () => setDropDownMenu((prevState) => !prevState);
  return (
    <>
      <header className="header">
        <nav className="nav justify-content-between aling-items-center">
          <div className="d-flex">
            <div className="navbar-logo">
              <NavLink to="/admin/dashboard" className="navbar-brand c-pointer">
                <img src={ImgLogo} alt="" className="img-fluid" />
              </NavLink>
            </div>
          </div>

          <Dropdown
            direction="down"
            isOpen={dropDownMenu}
            toggle={toggle}
            tag="div"
          >
            <DropdownToggle
              tag="div"
              caret={false}
              className="nav-link dropdown-no-icon dropdown-toggle p-0 bg-transparent border-0 shadow-none mx-auto"
            >
              <span className="username me-2">Paul Arute</span>
              <img src={ImgAvatar} alt="" className="img-fluid" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag="div">
                <NavLink to="/">Profile</NavLink>
              </DropdownItem>
              <DropdownItem tag="div">
                <NavLink to="/">Change Password</NavLink>
              </DropdownItem>
              <DropdownItem>
                <NavLink to="/">Sign Out</NavLink>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </nav>
      </header>
      <div className="page-wrapper">
        <div className="app-sidebar">
          <div className="as-menu">
            <nav className="nav-list list-unstyled">
              <NavItem className="as-menu-item">
                <NavLink to="/dashboard" className="as-inner-item">
                  <span className="as-icon">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.90799 4.90797C2.83333 6.98263 2.83333 10.3217 2.83333 17C2.83333 23.6782 2.83333 27.0173 4.90799 29.092C6.98265 31.1666 10.3218 31.1666 17 31.1666C23.6782 31.1666 27.0173 31.1666 29.092 29.092C31.1667 27.0173 31.1667 23.6782 31.1667 17C31.1667 10.3217 31.1667 6.98263 29.092 4.90797C27.0173 2.83331 23.6782 2.83331 17 2.83331C10.3218 2.83331 6.98265 2.83331 4.90799 4.90797ZM19.4792 14.1666C19.4792 14.7534 19.9549 15.2291 20.5417 15.2291H21.5182L18.6671 18.0803C18.5288 18.2186 18.3045 18.2186 18.1662 18.0803L15.9197 15.8337C14.9515 14.8656 13.3818 14.8656 12.4136 15.8337L9.16536 19.082C8.75043 19.4969 8.75043 20.1697 9.16536 20.5846C9.58029 20.9995 10.253 20.9995 10.668 20.5846L13.9162 17.3363C14.0545 17.198 14.2788 17.198 14.4171 17.3363L16.6636 19.5829C17.6318 20.5511 19.2015 20.5511 20.1697 19.5829L23.0208 16.7317V17.7083C23.0208 18.2951 23.4965 18.7708 24.0833 18.7708C24.6701 18.7708 25.1458 18.2951 25.1458 17.7083V14.1666C25.1458 13.5798 24.6701 13.1041 24.0833 13.1041H20.5417C19.9549 13.1041 19.4792 13.5798 19.4792 14.1666Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="as-item-text">Dashboard</span>
                </NavLink>
              </NavItem>
              <NavItem className="as-menu-item">
                <NavLink to="/portfolio" className="as-inner-item">
                  <span className="as-icon">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.5 4.25H8.49999C5.36916 4.25 2.83333 6.77167 2.83333 9.87417V24.1258C2.83333 27.2283 5.36916 29.75 8.49999 29.75H25.5C28.6308 29.75 31.1667 27.2283 31.1667 24.1258V9.87417C31.1667 6.77167 28.6308 4.25 25.5 4.25ZM12.0417 10.1575C13.8408 10.1575 15.3142 11.6308 15.3142 13.43C15.3142 15.2292 13.8408 16.7025 12.0417 16.7025C10.2425 16.7025 8.76916 15.2292 8.76916 13.43C8.76916 11.6308 10.2425 10.1575 12.0417 10.1575ZM17.5242 23.6017C17.3967 23.7433 17.1983 23.8283 17 23.8283H7.08333C6.88499 23.8283 6.68666 23.7433 6.55916 23.6017C6.43166 23.46 6.36083 23.2617 6.37499 23.0633C6.61583 20.6833 8.51416 18.7992 10.8942 18.5725C11.645 18.5017 12.4242 18.5017 13.175 18.5725C15.555 18.7992 17.4675 20.6833 17.6942 23.0633C17.7225 23.2617 17.6517 23.46 17.5242 23.6017ZM26.9167 23.7292H24.0833C23.5025 23.7292 23.0208 23.2475 23.0208 22.6667C23.0208 22.0858 23.5025 21.6042 24.0833 21.6042H26.9167C27.4975 21.6042 27.9792 22.0858 27.9792 22.6667C27.9792 23.2475 27.4975 23.7292 26.9167 23.7292ZM26.9167 18.0625H21.25C20.6692 18.0625 20.1875 17.5808 20.1875 17C20.1875 16.4192 20.6692 15.9375 21.25 15.9375H26.9167C27.4975 15.9375 27.9792 16.4192 27.9792 17C27.9792 17.5808 27.4975 18.0625 26.9167 18.0625ZM26.9167 12.3958H19.8333C19.2525 12.3958 18.7708 11.9142 18.7708 11.3333C18.7708 10.7525 19.2525 10.2708 19.8333 10.2708H26.9167C27.4975 10.2708 27.9792 10.7525 27.9792 11.3333C27.9792 11.9142 27.4975 12.3958 26.9167 12.3958Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="as-item-text">Portfolio</span>
                </NavLink>
              </NavItem>
            </nav>
          </div>
        </div>
        <div className="page-content">
          <div
            className="page-banner"
            style={{ backgroundImage: `url(${ImgBannerBG})` }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-6">
                  <h1 className="page-heading mb-4 mb-md-0">
                    Nice! Your investment has grown by{" "}
                    <span className="font-700">$74,200</span>, an estimated
                    annual return of
                    <span className="font-700"> 47.2%.</span>
                  </h1>
                </div>
                <div className="col-12 col-md-6">
                  <div className="card-account">
                    <div className="card-body">
                      <div className="card-top">
                        <div className="ca-icon">
                          <img src={Imgdollar} alt="" className="img-fluid" />
                        </div>
                        <div>
                          <h2 className="ca-heading">$100,000</h2>
                          <p className="ca-sub-heading">account balance</p>
                        </div>
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item d-flex justify-content-between align-items-center py-3 font-500">
                          Active investment
                          <span className="font-700">$80,000</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center py-3 font-500">
                          Cash
                          <span className="font-700">$20,000</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            {/* <div className="card-chart">
              <div className="card-body">
                <div className="cc-top">
                  <h3 className="cc-heading">Balance</h3>
                  <div className="">
                    <Input
                      type="select"
                      name="select"
                      id="exampleSelect"
                      bsSize="sm"
                    >
                      <option>All time</option>
                      <option>Last Week</option>
                      <option>This Months</option>
                      <option>Last Months</option>
                    </Input>
                  </div>
                </div>
                <div className="balance-chart">
                  <VictoryChart
                    width={830}
                    height={250}
                    style={{ grid: { stroke: "none" } }}
                  >
                    <VictoryArea
                      // animate={{
                      //   duration: 2 * 1000,
                      //   onLoad: { duration: 2 * 1000 },
                      // }}
                      style={{
                        data: {
                          fill: "#F189F7",
                          fillOpacity: 0.55,
                          stroke: "#6555DF",
                          strokeWidth: 2,
                        },
                      }}
                      interpolation="natural"
                      labels={({ datum }) => datum.label}
                      data={[
                        { x: "Feb", y: 0, label: "$0" },
                        { x: "Mar", y: 10000, label: "$10,000" },
                        { x: "Apr", y: 14000, label: "$14,000" },
                        { x: "May", y: 33000, label: "$33,000" },
                        { x: "Jun", y: 41000, label: "$41,000" },
                        { x: "Jul", y: 61000, label: "$61,000" },
                        { x: "Aug", y: 75000, label: "$75,000" },
                      ]}
                      labelComponent={
                        <VictoryTooltip
                          // backgroundStyle={{ fill: "pink" }}
                          // backgroundPadding={3}
                          flyoutStyle={{ stroke: "tomato", strokeWidth: 2 }}
                        />
                      }
                    />
                    <VictoryAxis />
                  </VictoryChart>
                  {/* <h1 className="text-center">Chart coming soon..</h1> */}
            {/* </div>
              </div>
            </div> */}
            <IndexChart />
          </div>
        </div>
      </div>
      <Modal className="account-modal" isOpen={modal} toggle={togglemodal}>
        <ModalHeader toggle={togglemodal}>
          <img src={Imgmodalheader} alt="" className="img-fluid" />
        </ModalHeader>
        <ModalBody>
          <h2 className="mb-2 text-center">Your investment</h2>
          <p className="mb-23 text-center">
            This is what you have invested as a syndicator and indicates how
            much of your funds have been allocated across deals.
          </p>
          <div className="card-account modal-account">
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex justify-content-between align-items-center py-3 font-500">
                  Investment amount
                  <span className="font-700 " onClick={togglemodal}>
                    $100,000
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center py-3 font-500">
                  Amount allocated
                  <span className="font-700">
                    {/* <span className="text-success"> (74%) </span> (47.2%) */}
                    <span className="text-pink"> (74%)</span> $74,120
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center py-3 font-500">
                  Deals allocated
                  <span className="font-700 " onClick={togglemodal}>
                    18
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center py-3 font-500">
                  Total gains
                  <span className="font-700">
                    <span className="text-success"> (47.2%) </span> $47,244
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
