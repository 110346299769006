import { Navigate } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "../helpers/utils";

const UnProtectedRoute = ({ children }) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.USERID) ?? null;

  if (token) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default UnProtectedRoute;
