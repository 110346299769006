import classNames from "classnames";

export function Snapshot({
  image = "",
  bgColor = "",
  textColor = "",
  title = "",
  amount = "",
  isShowCurrency = false,
  isComingSoon = false
}) {
  return (
    <div className="col">
      <div className={classNames("mb-4 snapshot-card", bgColor, textColor, isComingSoon ? 'opacity-50' : 'opacity-100')}>
        <div className="d-flex flex-lg-column justify-content-center">
          <img className="w-12" alt="" src={image} />
          <p className="mt-lg-2 snapshot-card__title">{title}</p>
        </div>
        <p className={classNames("snapshot-card__amount", isComingSoon ? "coming-soon" : "")}>
          {isComingSoon ? "Coming soon" : isShowCurrency ? "$" + amount.toLocaleString('en-US') : amount}
        </p>
      </div>
    </div>
  );
}
