import { ApiClient } from "../api";

export async function login(email, password) {
  return ApiClient.post(`/login`, {
    email,
    password,
  });
}

export async function getUserSnapshotData(fromDate, toDate) {
  let url = "/snapshotData";

  if (fromDate && toDate) {
    url += `?fromDate=${fromDate}&toDate=${toDate}`;
  }
  return ApiClient.get(url);
}

export async function getUserInfo() {
  const url = "/info";
  return ApiClient.get(url);
}
